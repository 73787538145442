import React from 'react'

import { LegalPage } from 'app/marketing'

export default function Terms() {
  return (
    <LegalPage>
      <h3>REALVISION INC. TERMS OF USE</h3>
      <p>
        REALVISION INC. (“REALVISION”) OWNS AND OPERATES THE WEBSITE (THE "WEBSITE") LOCATED AT http://real.vision AND OTHER DOMAINS AUTHORIZED IN WRITING BY REALVISION. THESE TERMS OF USE APPLY TO ALL USERS OF THE WEBSITE, INCLUDING USERS WHO ORDER AND/OR USE SERVICES PROVIDED THROUGH THIS WEBSITE AND USERS WHO SIMPLY VIEW THE CONTENT ON OR AVAILABLE THROUGH THIS WEBSITE.  PLEASE READ THESE TERMS OF USE CAREFULLY. BY USING THIS WEBSITE YOU INDICATE YOUR ACCEPTANCE OF THESE TERMS OF USE. IF YOU DO NOT ACCEPT THESE TERMS OF USE, THEN DO NOT USE THIS WEBSITE OR ANY OF ITS CONTENT OR SERVICES.  THESE TERMS OF USE MAY BE AMENDED OR UPDATED BY REALVISION FROM TIME TO TIME WITHOUT NOTICE. THE TERMS OF USE MAY HAVE CHANGED SINCE YOUR LAST VISIT TO THIS WEBSITE, AND IT IS YOUR RESPONSIBILITY TO REVIEW THESE TERMS OF USE FOR ANY CHANGES.  YOUR USE OF THE WEBSITE AFTER ANY AMENDMENTS OR UPDATES OF THESE TERMS OF USE SHALL SIGNIFY YOUR ASSENT TO AND ACCEPTANCE OF SUCH REVISED TERMS.  ANY NEW FEATURES THAT MAY BE ADDED TO THIS WEBSITE FROM TIME TO TIME WILL BE SUBJECT TO THESE TERMS OF USE, UNLESS STATED OTHERWISE.  YOU SHOULD VISIT THIS PAGE PERIODICALLY TO REVIEW THESE TERMS OF USE.
      </p>
      <p>
        The Realvision service provides users with photography services and creation of professional property listings that include still photography, virtual tours and floor plans created for customers ("Customers") to be featured on the Website (the "Service").  In order to register for the Service, please contact us at info@real.vision. Customer represents and warrants that Customer has the right to enter into this agreement, including without limitation express permission from the applicable property owners as necessary to engage and use our Services as described in this agreement and for Realvision to create and use images and videos of the applicable property owners’ properties.  Customer agrees to defend, indemnify and hold harmless Realvision, its agents, officers, directors, employees and contractors from and against any demands, claims, losses, damages, liabilities, expenses and costs (including reasonable attorney’s fees) arising in connection with any breach or alleged breach of the preceding warranty.
      </p>
      <ol>
        <li>
          <p>
            <strong>Conduct.</strong> You shall not (a) copy or distribute any part of this Website (including all of the contents of the website) unless expressly permitted to do so; (b) impersonate another person or entity or misrepresent your affiliation with any person or entity; (c) interfere with or disrupt the Website or servers or networks connected to the Website, or disobey any requirements, procedures, policies or regulations of networks connected to the Website or probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures;  (d) intentionally or unintentionally violate any applicable local, state, national or international law or regulation; or (e) resell the content of the Website, the use of the Website or access to the Website or its contents.
          </p>
          <p>
            You also agree not to access the Website in a manner that utilizes the resources of the Website more heavily than would be the case for an individual person using a conventional web browser.  Notwithstanding the foregoing, operators of public search engines may use spiders or other bots for the purpose of creating publicly available searchable indices of the materials on this Website.
          </p>
        </li>
        <li>
          <p>
            <strong>Intellectual Property Rights and Licenses.</strong> Realvision owns all right, title and interest in and to all photos, videos, virtual tours, floor plans, and other content and compilations thereof ("Realvision Content") created for Customer by Realvision or any agent authorized by Realvision.
          </p>
        </li>
        <li>
          <p>
            <strong>Feedback.</strong> If You provide Realvision with any suggestions, comments or other feedback ("Feedback") relating to the Website, Realvision may use such Feedback in the Website or in any other Realvision products or services (collectively, "Realvision Offerings"). Accordingly, You agree that: (a) Realvision is not subject to any confidentiality obligations in respect to the Feedback, (b) the Feedback is not confidential or proprietary information of You or any third party and You have all of the necessary rights to disclose the Feedback to Realvision, (c) Realvision (including all of its successors and assigns and any successors and assigns of any of the Realvision Offerings) may freely use, reproduce, publicize, license, distribute, and otherwise commercialize Feedback in any Realvision Offerings, and (d) You are not entitled to receive any compensation or re-imbursement of any kind from Realvision or any of the other users of the Website.
          </p>
        </li>
        <li>
          <p>
            <strong>Use of the Website.</strong>  Realvision may alter, suspend, or discontinue this Website at any time and for any reason or no reason, without notice. The Website may be unavailable from time to time due to maintenance or malfunction of computer or network equipment or other reasons.  Realvision may periodically add or update the information and materials on this Website without notice.
          </p>
        </li>
        <li>
          <p>
            <strong>Advertising.</strong> You acknowledge and agree that the Website may be provided with advertisements.  If You elect to have any business dealings with anyone whose products or services may be advertised on the website, You acknowledge and agree that such dealings are solely between You and such advertiser and You further acknowledge and agree that Realvision shall not have any responsibility or liability for any losses or damages that You may incur as a result of any such dealings.  You shall be responsible for obtaining access to the Website and acknowledge that such access may involve third-party fees (such as Internet service provider access or data fees).  You shall be solely responsible for any such fees and also for obtaining any equipment that is required to access the website.  It is your responsibility to ascertain whether any information or materials downloaded from this website are free of viruses, worms, Trojan Horses, or other items of a potentially destructive nature.
          </p>
        </li>
        <li>
          <p>
            <strong>DISCLAIMER OF ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS.</strong>  THE INFORMATION AND MATERIALS PROVIDED ON THIS WEBSITE ARE PROVIDED "AS IS."  SPECIFICALLY, BUT WITHOUT LIMITATION, REALVISION AND ITS AUTHORIZED AGENTS DO NOT REPRESENT, WARRANT OR OFFER ANY CONDITIONS THAT: (I) THE INFORMATION OR MATERIALS ON THIS WEBSITE ARE CORRECT, ACCURATE, RELIABLE OR COMPLETE; (II) THE FUNCTIONS CONTAINED ON THIS WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE; (III) DEFECTS WILL BE CORRECTED, OR (IV) THIS WEBSITE OR THE SERVER(S) THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.  REALVISION SPECIFICALLY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS, EITHER EXPRESS, IMPLIED, STATUTORY, BY USAGE OF TRADE, COURSE OF DEALING OR OTHERWISE INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, TITLE, SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE.  ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE WEBSITE IS AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, LOSS OF DATA, OR ANY OTHER LOSS THAT RESULTS FROM DOWNLOADING OR USING ANY SUCH MATERIAL.  REALVISION DOES NOT WARRANT, ENDORSE, GUARANTEE, PROVIDE ANY CONDITIONS OR REPRESENTATIONS, OR ASSUME ANY RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY ANY THIRD PARTY THROUGH THE WEBSITE OR IN RESPECT TO ANY WEBSITE THAT CAN BE REACHED FROM A LINK ON THE WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING ON THE WEBSITE, AND REALVISION SHALL NOT BE A PARTY TO ANY TRANSACTION THAT YOU MAY ENTER INTO WITH ANY SUCH THIRD PARTY.
          </p>
          <p>
            <strong>NO RESPONSIBILITY FOR CONTENT.</strong> You acknowledge and understand that the content made available on the Website is entirely the responsibility of the person from whom such content originated. Realvision will not be liable for any information or content submitted to Realvision by Customer or any third party.
          </p>
        </li>
        <li>
          <p>
            <strong>LIMITATION OF LIABILITY.</strong> UNDER NO CIRCUMSTANCES SHALL REALVISION BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR OTHER DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR OTHER DAMAGES THAT RESULT FROM (I) YOUR USE OF OR YOUR INABILITY TO USE, THIS WEBSITE OR ANY OF THE INFORMATION OR MATERIALS CONTAINED ON THIS WEBSITE, (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS, DATA, INFORMATION OR SERVICES, (III) ERRORS, MISTAKES, OR INACCURACIES IN THE MATERIALS ON THE WEBSITE, (IV) PERSONAL INJURY OR PROPERTY DAMAGE OF ANY KIND WHATSOEVER ARISING FROM OR RELATING TO YOUR USE OF THE WEBSITE, ANY BUGS, VIRUSES, TROJAN HORSES, OR ANY OTHER FILES OR DATA THAT MAY BE HARMFUL TO COMPUTER OR COMMUNICATION EQUIPMENT OR DATA THAT MAY HAVE BEEN TRANSMITTED TO OR THROUGH THE WEBSITE, OR (V) ANY ERRORS OR OMISSIONS IN ANY MATERIAL ON THE WEBSITE OR ANY OTHER LOSS OR DAMAGE OF ANY KIND ARISING FROM OR RELATING TO YOUR USE OF THE WEBSITE.  THESE LIMITATIONS SHALL APPLY EVEN IF REALVISION HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  THE FOREGOING LIMITATIONS SHALL APPLY TO THE FULLEST EXTENSION PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
          </p>
        </li>
        <li>
          <p>
            <strong>INDEMNIFICATION.</strong> YOU SHALL INDEMNIFY AND HOLD REALVISION AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, AGENTS, AND EMPLOYEES, HARMLESS FROM ALL CLAIMS, THREATS, ACTIONS, PROCEEDINGS, DEMANDS, DAMAGES, LOSSES, OBLIGATIONS, COSTS, AND EXPENSES INCLUDING REASONABLE ATTORNEYS' FEES, MADE BY ANY THIRD PARTY DUE TO OR ARISING OUT OF MATERIALS SUBMITTED, POSTED, TRANSMITTED OR MADE AVAILABLE BY YOU THROUGH THE WEBSITE, YOUR USE OF THE WEBSITE, ANY VIOLATION BY YOU OF THESE TERMS OF USE, OR ANY VIOLATION BY YOU OF ANY RIGHTS OF ANOTHER (INCLUDING, WITHOUT LIMITATION, ALL INTELLECTUAL PROPERTY RIGHTS AND RIGHTS OF PUBLICITY, PERSONALITY OR PRIVACY).
          </p>
        </li>
        <li>
          <p>
            <strong>Links to Third-Party Websites.</strong> This Website may contain links to other websites that are not owned or controlled by Realvision.  Realvision is not responsible for the content of any linked websites.  Any third-party websites or services accessed from the Website are subject to the terms and conditions of those websites and or services and You are responsible for determining those terms and conditions and complying with them.  The presence on the Website of a link to any non-Realvision websites does not imply that Realvision endorses or accepts any responsibility for the content or use of such websites, and You hereby release Realvision from all liability and/damages that may arise from Your use of such websites or receipt of services from any such websites.
          </p>
          <p>
            While Realvision encourages links to the Website, it does not wish to be linked to or from any third-party website which (i) contains, posts or transmits any unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar, pornographic, profane or indecent information of any kind, including, without limitation, any content constituting or encouraging conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any local, state, provincial, national or international law, regulation which may be damaging or detrimental to the activities, operations, credibility or integrity of Realvision for which contains, posts or transmits any material or information of any kind which promotes racism, bigotry, hatred or physical harm of any kind against any group or individual, could be harmful to minors, harasses or advocates harassment of another person, provides material that exploits people under the age of 18 in a sexual or violent manner, provides instructional information about illegal activities, including, without limitation, the making or buying of illegal weapons; or (ii) contains, posts or transmits any information, software or other material which violates or infringes upon the rights of others, including material which is an invasion of privacy or publicity rights, or which is protected by copyright, trademark or other proprietary rights. Realvision reserves the right to prohibit or refuse to accept any link to the Website, including, without limitation, any link which contains or makes available any content or information of the foregoing nature, at any time. You agree to remove any link you may have to the Website upon the request of Realvision.
          </p>
        </li>
        <li>
          <p>
            <strong>No Implied Endorsements.</strong> In no event shall any reference to any third party (including any Customer), third party product or service (including such product or service provided by any Customer) be construed as an approval or endorsement by Realvision of that third party, third party product or service.
          </p>
        </li>
        <li>
          <p>
            <strong>Termination.</strong> Realvision may, under certain circumstances and without prior notice, immediately terminate Your ability to access the Website or portions thereof. Cause for such termination shall include, but not be limited to, (a) breaches or violations of the terms of use or any other agreement that You may have with Realvision (including, without limitation, non-payment of any fees owed in connection with the website or otherwise owed by You to Realvision), (b) requests by law enforcement or other government agencies, (c) a request by You, (d) discontinuance or material modification to the website (or any part thereof), (e) unexpected technical, security or legal issues or problems, and/or (f) participation by You, directly or indirectly, in fraudulent or illegal activities.  Termination of Your access to the Website may also include removal of some or all of the materials uploaded by You to the Website.  You acknowledge and agree that all terminations may be made by Realvision in its sole discretion and that Realvision shall not be liable to You or any third-party for any termination of Your access to this Website or for the removal of any of the materials uploaded by You to the Website.  Any termination of these terms of use by Realvision shall be in addition to any and all other rights and remedies that Realvision may have.
          </p>
        </li>
        <li>
          <p>
            <strong>Security.</strong> Information sent or received over the Internet is generally unsecure and Realvision cannot and does not make any representation or warranty concerning security of any communication to or from the Web site or any representation or warranty regarding the interception by third parties of personal or other information.
          </p>
        </li>
        <li>
          <p>
            <strong>Enforcement of Terms and Conditions.</strong> If any part of these Terms of Use is unlawful, void, or unenforceable, that part shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions.  The failure of Realvision to exercise or enforce any right or provision under these Terms of Use shall not constitute a waiver of such right or provision.  Any waiver of any right or provision by Realvision must be in writing and shall only apply to the specific instance identified in such writing.  No waiver by Realvision of any provision of these terms of use shall be deemed to be a further or continuing waiver of such provision or any other provision.  You agree that any cause of action that You may have arising out of or related to this website or Your use of this website must commence within one (1) year after the cause of action accrues, otherwise such cause of action shall be permanently barred.
          </p>
        </li>
        <li>
          <p>
            <strong>Assignment.</strong> You may not assign the terms of use, or any rights or licenses granted hereunder, whether voluntarily, by operation of law, or otherwise without Realvision’s prior written consent. Realvision may assign the terms of use, or any rights or licenses granted hereunder, at any time without notice.
          </p>
        </li>
        <li>
          <p>
            <strong>Survival.</strong> In addition to Sections 6, 7 and 8, all representations, warranties, conditions and indemnities made by You in these Terms of Use shall survive the termination of these Terms of Use.  Unless otherwise explicitly identified as terminating elsewhere in these Terms of Use, all licenses granted by You in these terms of use shall survive their termination.  All payment obligations incurred by You prior to the termination of these terms of use shall survive their termination.
          </p>
        </li>
        <li>
          <p>
            <strong>Entire Agreement.</strong> Unless governed by a separate agreement signed by both parties, these Terms of Use, together with our Privacy Policy, constitutes the entire agreement between the parties relating to the Website and all related activities.  These Terms of Use shall not be modified except in writing signed by both parties or by a new posting of these Terms of Use issued by Realvision.
          </p>
          <p>
            Last Updated: March 17, 2017
          </p>
        </li>
      </ol>
    </LegalPage>
  )
}
